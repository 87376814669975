.redirection-whatsapp {
    &-image {
        position: fixed;
        bottom: 45px;
        right: 10px;
        z-index: 999;

        a {
            display: flex;
            align-items: center;
            text-decoration: none;
            gap: 10px;

            &:hover {
                transition: 0.5s;
                
                circle {
                    fill: #128c7e;
                    transition: 0.5s;
                }
            }

            .redirection-text {
                color: rgba(0, 0, 0, .8);
                border-radius: 16px;
                padding: 0 14px;
                height: 32px;
                background: #fff;
                transition: opacity .3s ease-out .4s;
                filter: drop-shadow(0 1px 4px rgba(0, 0, 0, .4));
                border: none;
                display: flex;
                align-items: center;
                position: relative;
                font-size: 16px;

                @media (max-width: 820px) {
                    font-size: 14px;
                }

                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 10px;
                    right: -6px;
                    border: 8px solid transparent;
                    border-width: 6px 0 6px 8px;
                    border-left-color: #fff;
                }
            }
        }
    }
}