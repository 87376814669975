.btn-cta {
    background-color: #ffffff;
    color: #412669;
    transition: 0.5s;
    text-decoration: none;
    padding: 20px 40px;
    font-size: 18px;
    line-height: 1;
    font-weight: 500;
    border-radius: 100px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 38%;

    @media (max-width: 999px) {
        width: auto;
        padding: 20px 20px;
    }


    &:hover {
        background-color: #05e500;
    }

    span {
        font-weight: 700;
        margin-top: 7px;
    }
}