.analysis {
    background: #FFFFFF;
    padding: 100px 0 102px 0;

    @media (min-width: 821px) and (max-width: 1280px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media (max-width: 820px) {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 10px;
        padding-top: 64px;
    }

    &-container {
        max-width: 1308px;
        margin: 0 auto;
        background-color: #DFEBFD;
        border-radius: 20px;
        display: flex;
        justify-content: space-between;
        padding: 41px 31px 41px 37px;

        @media (max-width: 820px) {
            justify-content: center;
            flex-wrap: wrap;
            padding: 31px 15px 36px 15px;
        }
    }

    &-content {
        max-width: 684px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-right: 131px;

        @media (min-width: 821px) and (max-width: 1280px) {
            margin-right: 50px;
        }

        @media (max-width: 820px) {
            margin-right: 0;
        }

        &-info {
            max-width: 289px;

            @media (min-width: 821px) and (max-width: 1202px) {
                max-width: 220px;
            }

            @media (min-width: 821px) and (max-width: 993px) {
                margin-bottom: 30px;
            }

            @media (max-width: 820px) {
                margin-bottom: 37px;
            }

            &-image {
                img {
                    width: 90px;
                    height: 90px;
                }
            }

            &-title {
                h3 {
                    color: #000000;
                    font-size: 22px;
                    font-weight: 600;
                    line-height: 101.5%;
                    margin: 20px 0;
                }
            }

            &-text {
                p {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 150%;
                }
            }
        }

        &-info:first-child, &-info:nth-child(2) {
            @media (min-width: 1024px) {
                margin-bottom: 30px;
            }
        }
    }

    &-card {
        background: linear-gradient(100deg, #C13467 0%, #882C68 19.36%, #412669 92.41%);
        padding: 45px 51px 26px 30px;
        max-width: 385px;
        border-radius: 20px;

        @media (min-width: 821px) and (max-width: 993px) {
            max-height: 750px;
        }

        @media (min-width: 821px) and (max-width: 1100px) {
            max-width: 315px;
        }

        @media (max-width: 820px) {
            margin-top: 47px;
            padding: 37px 23px 67px 23px;
        }

        &-title {
            h2 {
                font-size: 30px;
                line-height: normal;
                color: #FFFFFF;
                font-weight: 700;
                margin-bottom: 38px;

                @media (max-width: 820px) {
                    font-size: 25px;
                }
            }
        }

        &-text {
            font-size: 22px;
            font-weight: 300;
            line-height: normal;
            color: #FFFFFF;
            margin-bottom: 78px;

            @media (max-width: 820px) {
                font-size: 18px;
                margin-bottom: 45px;
            }

            p {
                margin-bottom: 10px;
            }
        }

        &-btn {
            text-align: left;

            @media (max-width: 820px) {
                text-align: center;
            }
        }
    }
}