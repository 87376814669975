.btn-price {
  color: #ffffff;
  cursor: pointer;
  background: #169D6C;
  border-radius: 100px;
  font-weight: 700;
  font-size: 24px;
  padding: 20px 40px;
  transition: 0.5s;
  text-decoration: none;

  &:hover {
    background: #05E500;
    color: #412669;
  }

  &-product {
    background: #05E500;
    color: #412669;

    &:hover {
      color: #ffffff;
      background: #169D6C;
    }
  }
}