.differentials {
    background: linear-gradient(
        -66deg,
        #c13467 3.43%,
        #882c68 21.2%,
        #412669 88.24%
    );
    padding-top: 50px;
    padding-bottom: 69px;
    z-index: 1;
    position: relative;

    @media (min-width: 821px) and (max-width: 1280px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media (max-width: 820px) {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 29px;
        padding-bottom: 0;
        background: linear-gradient(
        -88deg,
        #c13467 3.43%,
        #882c68 21.2%,
        #412669 88.24%
        );
    }

    &-container {
        max-width: 1246px;
        margin: 0 auto;
    }

    &-content {
        &-title {
            color: #FFFFFF;
            line-height: normal;
            text-align: center;

            &-primary {
                font-weight: 300;
                font-size: 40px;

                @media (max-width: 820px) {
                    font-size: 36px;
                }
            }

            &-secondary {
                font-weight: 700;
                font-size: 40px;

                @media (max-width: 820px) {
                    font-size: 36px;
                }
            }
        }

        &-cards {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: 90px;

            @media (max-width: 820px) {
               justify-content: center;
               margin-top: 63px;
            }

            .differentials-card:nth-child(1), .differentials-card:nth-child(2), .differentials-card:nth-child(3), .differentials-card:nth-child(4) {
                @media (min-width: 1024px) {
                    margin-bottom: 90px;
                }
            }

            .differentials-card:last-child {
                .differentials-card-text {
                    a {
                        text-decoration: underline;
                    }
                }
            }

            .differentials-card {
                text-align: center;
                width: 289px;

                @media (max-width: 820px) {
                    margin-bottom: 63px;
                }

                &-image {
                    img {
                        width: 90px;
                        height: 90px;
                    }
                }

                &-title {
                    h3 {
                        font-weight: 600;
                        font-size: 22px;
                        color: #FFFFFF;
                        margin-top: 20px;
                        margin-bottom: 10px;
                        line-height: 91.5%;

                        @media (max-width: 820px) {
                            font-size: 18px;
                            margin-bottom: 10px;
                            margin-top: 15px;
                        }
                    }
                }

                &-text {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 150%;
                    color: #FFFFFF;

                    a {
                        color: #FFFFFF;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}