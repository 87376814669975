.product {
    background-color: #FFFFFF;
    padding-top: 102px;
    padding-bottom: 64px;

    @media (min-width: 821px) and (max-width: 1350px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media (max-width: 820px) {
        padding-top: 60px;
        padding-bottom: 88px;
        padding-left: 77px;
        padding-right: 77px;
    }

    @media (max-width: 620px) {
        padding-left: 30px;
        padding-right: 30px;
    }

    &-container {
        max-width: 1322px;
        margin: 0 auto;
    }

    &-content {
        &-title {
            text-align: center;
            margin-bottom: 94px;

            @media (max-width: 820px) {
                margin-bottom: 60px;
            }

            &-primary {
                font-size: 40px;
                color: #000;
                font-weight: 600;
                line-height: 103.5%;

                @media (max-width: 820px) {
                    font-size: 36px;
                }
            }

            &-secondary {
                font-size: 40px;
                font-weight: 400;
                line-height: 103.5%;
                color: #412669;

                @media (max-width: 820px) {
                    font-size: 36px;
                }
            }
        }

        &-cards {
            display: flex;
            justify-content: center;

            @media (max-width: 1146px) {
                align-items: center;
                flex-wrap: wrap;
            }

            &-card:nth-child(1) {
                @media (min-width: 821px) and (max-width: 1146px) {
                    margin-right: 50px;
                }

                .text-card {
                    margin-top: 54px;
                    margin-bottom: 94px;

                    @media (max-width: 820px) {
                        margin-top: 30px;
                        margin-bottom: 30px;
                    }
                }
            }

            &-card:nth-child(2) {
                margin-left: 50px;
                margin-right: 50px;

                @media (max-width: 1146px) {
                    margin-left: 0;
                    margin-right: 0;
                    margin-top: 60px;
                    margin-bottom: 60px;
                }

                .text-card {
                    margin-top: 54px;
                    margin-bottom: 52px;

                    @media (max-width: 820px) {
                        margin-top: 30px;
                        margin-bottom: 30px;
                    }
                }
            }

            &-card:nth-child(3) {
                @media (min-width: 821px) and (max-width: 1146px) {
                    margin-right: 50px;
                }

                .text-card {
                    margin-top: 54px;

                    @media (max-width: 1146px) {
                        margin-top: 30px;
                    }

                    @media (min-width: 821px) and (max-width: 1146px) {
                        margin-top: 54px;
                    }
                }
            }

            &-card:nth-child(4) {
                margin-left: 50px;

                @media (max-width: 1146px) {
                    margin-left: 0;
                    margin-top: 60px;
                }

                @media (min-width: 821px) and (max-width: 1146px) {
                    margin-top: 0px;
                }
            }

            &-card {
                background-color: #DFEBFD;
                border-radius: 50px;
                width: 293px;
                border: 5px solid #412669;
                padding: 34px 16px 60px 16px;
                position: relative;

                .title-card {
                    text-align: center;
                    margin-bottom: 30px;

                    h2 {
                        font-size: 16px;
                        color: #412669;
                        font-weight: 500;
                        line-height: normal;
                    }

                    h3 {
                        font-size: 20px;
                        color: #412669;
                        font-weight: 600;
                        line-height: normal;
                    }
                }

                .text-card {
                    text-align: left;
                    margin-bottom: 30px;

                    p {
                        font-size: 14px;
                        color: #412669;
                        font-weight: 400;
                        line-height: 153.5%;
                    }
                }

                .price-card {
                    text-align: center;
                    margin-bottom: 44px;

                    span {
                        font-size: 20px;
                        color: #412669;
                        font-weight: 300;
                        line-height: normal;
                    }

                    p {
                        font-size: 20px;
                        color: #412669;
                        font-weight: 700;
                        line-height: normal;
                    }
                }

                .btn-card {
                    text-align: center;

                    .btn-price {
                        font-size: 20px;
                    }
                }

                .card-line {
                    &-top {
                        z-index: 1;
                        height: 5px;
                        background-color: #DFEBFD;
                        position: absolute;
                        top: -5px;
                        width: calc(100% - 33%);
                        left: 16%;
                    }

                    &-bottom {
                        z-index: 1;
                        height: 5px;
                        background-color: #DFEBFD;
                        position: absolute;
                        bottom: -5px;
                        width: calc(100% - 33%);
                        left: 16%;
                    }
                }
            }
        }
    }
}