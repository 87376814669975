.frame {
  background: linear-gradient(
    -66deg,
    #c13467 3.43%,
    #882c68 21.2%,
    #412669 88.24%
  );
  padding: 55px 0 70px 0;
  position: relative;

  @media (min-width: 1001px) and (max-width: 1280px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 1000px) {
    padding: 25px 30px 0px 30px;
  }

  &-container {
    max-width: 1219px;
    margin: 0 auto;
    position: relative;

    &-content {
      display: flex;
      justify-content: space-between;

      @media (max-width: 1002px) {
        justify-content: center;
        flex-direction: column;
      }

      &-main {
        max-width: 554px;
        
        @media (max-width: 1002px) {
          margin: 0 auto;
        }
      }
    }

    &-logo {
      img {
        max-height: 60px;

        @media (max-width: 620px) {
          width: 100%;
        }
      }
    }

    &-info {
      &-title {
        color: #ffffff;
        font-weight: 700;
        font-size: 50px;
        line-height: 46px;
        text-align: left;
        width: 100%;
        margin-top: 57px;
        margin-bottom: 10px;

        @media (max-width: 620px) {
          font-size: 36px;
          line-height: 95.5%;
          margin-top: 63px;
        }
      }

      &-text {
        text-align: left;
        color: #ffffff;
        font-size: 19px;
        font-weight: 400;
        line-height: 150%;
        margin-bottom: 55px;
        max-width: 472px;

        @media (max-width: 620px) {
          margin-bottom: 40px;
          max-width: 100%;
          font-size: 17px;
        }
      }
    }

    &-btn {
      text-align: left;

      @media (max-width: 820px) {
        text-align: center;
      }
    }

    &-image {      
      text-align: center;

      @media (min-width: 1001px) {
        position: absolute;
        bottom: -74px;
        right: 0;
      }

      img {
        width: 600px;
        height: 607px;

        @media (min-width: 1001px) and (max-width: 1100px) {
          width: 500px;
          height: 506px;
        }

        @media (max-width: 1000px) {
          margin-top: 50px;
          width: 300px;
          height: 303px;
          margin-bottom: -4px;
        }
      }
    }
  }
}