.destiny {
    background-color: #FFFFFF;
    padding-top: 124px;

    @media (min-width: 1024px) and (max-width: 1140px)  {
        padding-top: 70px;
    }

    @media (min-width: 824px) and (max-width: 1280px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media (max-width: 820px) {
        padding-top: 145px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .container-landing-page {
        position: relative;
    }

    &-container {
        max-width: 1308px;
        margin: 0 auto;
    }

    &-trip {
        display: flex;
        justify-content: space-between;
        background: linear-gradient(
            -66deg,
            #c13467 3.43%,
            #882c68 21.2%,
            #412669 88.24%
        );
        border-radius: 20px;
        padding: 44px 31px 100px 0;
        position: relative;

        @media (max-width: 1023px) {
            justify-content: center;
            flex-wrap: wrap;
            padding: 0px 15px 49px 15px;
        }

        &-image {
            margin-top: -132px;

            @media (min-width: 375px) {
                margin-top: -139px;
            }

            @media (min-width: 390px) {
                margin-top: -146px;
            }

            @media (min-width: 400px) {
                margin-top: -155px;
            }

            @media (min-width: 1024px) {
                position: absolute;
                top: -124px;
                left: 61px;
                margin-top: 0;
            }

            @media (min-width: 1024px) and (max-width: 1140px)  {
                top: 5px;
                left: 35px;
            }

            img {
                width: 614px;
                height: 590px;

                @media (min-width: 1024px) and (max-width: 1140px)  {
                    top: 5px;
                }

                @media (max-width: 820px) {
                    width: 100%;
                    height: auto;
                }
            }
        }

        &-content {
            max-width: 472px;
            margin-left: 775px;

            @media (min-width: 1024px) and (max-width: 1100px){
                margin-left: 680px;
                margin-top: 59px;
            }

            @media (max-width: 1023px) {
                margin-left: 0;
                margin-top: 59px;
            }

            &-title {
                h2 {
                    font-size: 39px;
                    color: #FFFFFF;
                    font-weight: 700;
                    line-height: 103.5%;
                    margin-bottom: 35px;

                    @media (max-width: 820px) {
                        font-size: 36px;
                        margin-bottom: 30px;
                    }        
                }
            }
    
            &-text {
                p {
                    font-size: 18px;
                    color: #FFFFFF;
                    font-weight: 400;
                    line-height: 150%;
                    margin-bottom: 47px;

                    @media (max-width: 820px) {
                        font-size: 18px;
                    }
                }
            }

            &-btn {
                position: relative;
                z-index: 1;

                @media (max-width: 820px) {
                    text-align: center;
                }
            }
        }
    }

    &-travel {
        display: flex;
        padding: 100px 0 0 0;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        max-width: 1100px;
        margin: 0 auto;

        @media (max-width: 1000px) {
            justify-content: center;
            padding: 46px 15px 0px 15px;
        }

        &-image {
            @media (max-width: 981px) {
                margin-top: 70px;
            }

            img {
                width: 402px;
                height: 598px;
                margin-bottom: -4px;

                @media (max-width: 820px) {
                    width: 300px;
                    height: 446px
                }
            }
        }

        &-content {
            max-width: 510px;

            @media (max-width: 820px) {
                margin-top: 0;
            }
            
            &-title {
                h2 {
                    font-size: 40px;
                    color: #412669;
                    font-weight: 700;
                    line-height: 103.5%;
                    margin-bottom: 35px;

                    @media (max-width: 820px) {
                        font-size: 36px;
                        margin-bottom: 30px;
                    }
                }
            }
    
            &-text {
                font-size: 18px;
                color: #412669;
                font-weight: 400;
                line-height: 150%;
                margin-bottom: 47px;

                @media (max-width: 820px) {
                    font-size: 18px;
                }

                a {
                    color: #412669;
                }
            }

            &-btn {
                @media (max-width: 820px) {
                    text-align: center;
                }
            }
        }
    }
}