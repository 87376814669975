.benefits {
    padding: 80px 0 66px 0;

    @media (min-width: 824px) and (max-width: 1280px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media (max-width: 1103px) {
        padding: 30px 30px 50px 30px;
    }

    @media (max-width: 620px) {
        padding: 30px 29px 10px 29px;
    }

    &-container {
        max-width: 1216px;
        margin: 0 auto;
        text-align: center;
    }

    &-content {
        &-title {
            max-width: 1134px;
            margin: 0 auto;

            &--title {
                color: #000000;
                font-weight: 600;
                font-size: 40px;
                line-height: 47px;
                margin: 0;

                @media (max-width: 620px) {
                    font-size: 25px;
                    line-height: 103.5%;
                }
            }

            &--title2 {
                color: #412669;
                font-weight: 400;
                font-size: 40px;
                line-height: 47px;

                @media (max-width: 620px) {
                    font-size: 25px;
                    line-height: 103.5%;
                }
            }
        }

        &-cards {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 60px;

            &-card {
                display: flex;
                width: 45%;
                margin-bottom: 51px;

                @media (max-width: 820px) {
                    width: 100%;
                    flex-wrap: wrap;
                    justify-content: center;
                }

                img {
                    height: 200px;
                    margin-right: 40px;
                    width: 184px;

                    @media (max-width: 820px) {
                        margin-right: 0;
                    }
                }

                &-info {
                    h3 {
                        font-weight: 600;
                        font-size: 22px;
                        line-height: 100.5%;
                        margin-top: 40px;
                        margin-bottom: 0;
                        text-align: left;
                        text-transform: uppercase;
    
                        @media (max-width: 820px) {
                            font-size: 20px;
                            margin-top: 20px;
                            text-align: center;
                        }
                    }
    
                    p {
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 140%;
                        margin-bottom: 0;
                        margin-top: 10px;
                        text-align: left;
    
                        @media (max-width: 820px) {
                            line-height: 120%;
                            text-align: center;
                        }
    
                        @media (max-width: 620px) {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}