@import "./frame.scss";
@import "./btn-price.scss";
@import "./container-landing-page.scss";
@import "./benefits.scss";;
@import "./footer-landing.scss";
@import "./analysis.scss";
@import "./destiny.scss";
@import "./differentials.scss";
@import "./tranquility.scss";
@import "./accordion-item.scss";
@import "./question.scss";
@import "./product.scss";
@import "./redirection-whatsapp.scss";
@import "./view.scss";
@import "./connection.scss";
@import "./btn-cta.scss";


body {
    margin: 0;
    font-family: Rubik,sans-serif;
    font-style: normal;
}

h1,
h2,
h3,
p {
    margin: 0;
}

ul {
    padding: 0;
}