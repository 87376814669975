.view {
    background-color: #FFFFFF;
    padding-top: 124px;
    padding-bottom: 184px;

    @media (min-width: 1103px) and (max-width: 1280px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media (max-width: 1103px) {
        padding-top: 230px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 60px;
    }

    .container-landing-page {
        position: relative;
    }

    &-container {
        max-width: 1308px;
        margin: 0 auto;
    }

    &-trip {
        display: flex;
        justify-content: space-between;
        background: linear-gradient(
            107deg,
            #c13467 3.43%,
            #882c68 21.2%,
            #412669 88.24%
        );
        border-radius: 20px;
        padding: 100px 31px 100px 0;
        position: relative;

        @media (min-width: 1103px) and (max-width: 1280px) {
            padding: 80px 31px 80px 0;
        }

        @media (max-width: 1103px) {
            justify-content: center;
            flex-direction: column;
            padding: 0px 15px 69px 15px;
            align-items: center;
        }

        &-watch {
            margin-top: -145px;

            @media (min-width: 1103px) {
                position: absolute;
                top: -75px;
                left: 135px;
                margin-top: 0;
            }

            @media (min-width: 1243px) and (max-width: 1280px) {
                top: -90px;
            }

            @media (min-width: 1191px) and (max-width: 1242px) {
                top: -55px;
            }

            @media (min-width: 1145px) and (max-width: 1190px) {
                top: -42px;
            }

            @media (min-width: 1117px) and (max-width: 1144px) {
                top: -25px;
            }

            @media (min-width: 1104px) and (max-width: 1116px) {
                top: -10px;
            }
            
            &-video {
                max-width: 442px;
                max-height: 884px;
                position: relative;

                @media (max-width: 1103px) {
                    max-width: 280px;
                    max-height: 467px;
                }

                .cel {
                    img {
                        width: 442px;
                        height: 738px;
                        z-index: 0;
                        position: relative;
    
                        @media (max-width: 1103px) {
                            width: 280px;
                            height: 467px;
                        }
                    }
                }

                .play {
                    img {
                        width: 124px;
                        height: 124px;
                        position: absolute;
                        top: 280px;
                        left: 155px;
                        z-index: 2;
                        pointer-events: none;
                        transition: opacity 0.6s;
                        opacity: 1;

                        @media (max-width: 1102px) {
                            width: 78px;
                            height: 78px;
                            left: 100px;
                            top: 196px;
                        }
                    }
                }

                .active {                    
                    img {
                        opacity: 0;
                    }
                }
    
                iframe {
                    position: absolute;
                    left: 15px !important;
                    width: 251px !important;
                    height: 448px !important;
                    top: 10px;
                    border-radius: 25px;
                    z-index: 1;

                    @media (min-width: 1103px) {
                        width: 395px !important;
                        height: 703px !important;
                        top: 18px !important;
                        left: 24px !important;
                        border-radius: 37px;
                    }
                }

                &-overlay {
                    z-index: 3;
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    background-color: transparent;
                    top: 0;
                    cursor: pointer;
                }
            }
        }

        &-content {
            max-width: 527px;
            margin-left: 750px;

            @media (min-width: 1103px) and (max-width: 1280px) {
                margin-left: 700px;
            }

            @media (max-width: 1103px) {
                margin-left: 0;
                margin-top: 22px;
            }

            &-title {
                h2 {
                    font-size: 39px;
                    color: #FFFFFF;
                    font-weight: 700;
                    line-height: 103.5%;
                    margin-bottom: 35px;

                    @media (max-width: 820px) {
                        font-size: 36px;
                        margin-bottom: 30px;
                    }        
                }
            }
    
            &-text {
                p {
                    font-size: 18px;
                    color: #FFFFFF;
                    font-weight: 400;
                    line-height: 150%;
                    margin-bottom: 47px;

                    @media (max-width: 820px) {
                        font-size: 18px;
                    }
                }
            }

            &-btn {
                position: relative;
                z-index: 1;

                @media (max-width: 820px) {
                    text-align: center;
                }
            }
        }
    }
}