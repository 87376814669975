.question {
    background: linear-gradient(
        -66deg,
        #c13467 3.43%,
        #882c68 21.2%,
        #412669 88.24%
    );
    z-index: 1;
    position: relative;
    padding-top: 50px;
    padding-bottom: 137px;

    @media (min-width: 821px) and (max-width: 1280px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media (max-width: 820px) {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 33px;
        padding-bottom: 108px;
    }

    &-container {
        max-width: 1246px;
        margin: 0 auto;
    }

    &-title {
        line-height: normal;
        color: #FFFFFF;
        text-align: center;
        margin-bottom: 75px;

        @media (max-width: 820px) {
            margin-bottom: 50px;
        }

        &-primary {
            font-size: 40px;
            font-weight: 300;

            @media (max-width: 820px) {
                font-size: 36px;
            }
        }

        &-secondary {
            font-size: 40px;
            font-weight: 700;

            @media (max-width: 820px) {
                font-size: 36px;
            }
        }
    }
}