.footer-landing {
    background: #5D2768;

    li {
        list-style: none;
        margin-bottom: 9px;

        a,p {
            color: #FFFFFF;
            font-weight: 400;
            font-size: 14px;
            text-decoration: none;
        }
    }

    &__body {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 59px;
        height: 100%;

        @media (max-width: 820px) {
            flex-direction: column;
            padding: 37px 30px;
        }
    }

    &__location {

        width: 31%;

        @media (max-width: 820px) {
            margin-bottom: 25px;
            width: 100%;
        }

        img {
            height: 63px;
            width: 386px;

            @media (max-width: 820px) {
                width: 300px;
                height: 49px;
            }
        }

        &-info {
            margin-top: 22px;
            
            li {
                line-height: 20px;
            }

            @media (max-width: 820px) {
                margin-bottom: 25px;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            span {
                color: #FFFFFF;
                font-weight: 400;
                font-size: 14px;

                a {
                    color: #EE981C;
                    text-decoration: underline;
                }
            }            
        }
    }

    &__link {

        a {
            transition: 0.3s;

            &:hover {
                color: #EE981C;
            }
        }

        @media (max-width: 820px) {
            margin-top: 0;
            margin-bottom: 47px;
        }

        &-title {
            color: #FFFFFF;
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 5px;

            @media (max-width: 820px) {
                font-weight: 600;
            }
        }
    }

    &__social {
        @media (max-width: 820px) {
            margin-bottom: 10px;
        }

        &-title {
            color: #FFFFFF;
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 5px;

            @media (max-width: 820px) {
                font-weight: 600;
            }
        }

        &-container {
            
            ul {
                display: flex;
                margin-bottom: 0;

                li {
                    margin-right: 10px;
                    
                    img {
                        height: 35px;
                        width: 35px;
                    }
                }
            }

            &--img {
                td {
                    text-align: start;
                }
                
                img {
                    height: 62px;
                    width: 104px;
                }
            }
        }
    }

    &__directs {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__contact {
        a {
            transition: 0.3s;
            
            &:hover {
                color: #EE981C;
            }
        }

        @media (max-width: 820px) {
            margin-top: 47px;
        }

        &-title, &-address {
            color: #FFFFFF;
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 5px;

            @media (max-width: 820px) {
                font-weight: 600;
            }
        }

        &-address {
            margin-top: 35px;
        }

        
        ul {
            li {
                img {
                    height: 100%;
                    margin-right: 5px;
                    max-height: 25px;
                }
            }
        }
        
    }

    .selo-digicert {
        width: 135px !important;
        order: 0;
        margin-bottom: 0;

        td {
            width: 135px !important;
            text-align: left;
            vertical-align: top;
        }

        a {
            color: #000000;
            text-decoration: none;
            font: bold 10px verdana, sans-serif;
            letter-spacing: 0.5px;
            text-align: center;
            margin: 0;
            padding: 0;
        }
    }

    .selo-reclameaqui {
        width: 95px;
        margin-left: 3px;
        height: 43px;
    }

    .ein-address {
        color: white; 
        font-size: 14px;
    }
}