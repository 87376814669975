.connection {
    background-color: rgb(37, 211, 102);
    padding: 0 20px;

    &-container {
        max-width: 1245px;
        margin: 0 auto;
        position: relative;

        .img-back {
            position: absolute;
            right: 120px;

            @media (min-width: 1000px) {
                height: 100%;
            }

            @media (max-width: 999px) {
                right: 0;
                bottom: 18%;
            }

            img {
                width: 100%;
                height: auto;

                @media (min-width: 1000px) {
                    height: 100%;
                }
            }
        }
    }

    &-content {
        display: flex;
        justify-content: space-between;
        position: relative;

        @media (max-width: 999px) {
            flex-direction: column;
        }

        &-main {
            padding: 40px 0;
            width: 50%;

            @media (max-width: 999px) {
                width: 100%;
            }

            img {
                width: 88px;
                height: 88px;
            }

            &-title {
                color: #412669;
                margin-top: 20px;
                font-size: 40px;
                line-height: 91.5%;
                font-weight: 700;

                @media (max-width: 999px) {
                    font-size: 35px;
                }
            }

            &-text {
                color: #412669;
                font-size: 20px;
                margin-top: 40px;
                line-height: 118%;
                margin-bottom: 40px;
                font-weight: 400;

                @media (max-width: 999px) {
                    font-size: 16px;
                    margin-top: 30px;
                    margin-bottom: 30px;
                }

                span {
                    font-weight: 700;
                }
            }
        }

        &-image {
            z-index: 1;
            margin-bottom: -4px;

            img {
                width: 100%;
                height: auto;
                max-width: 504px;
            }

            @media (min-width: 1000px) {
                position: absolute;
                right: 0;
                bottom: 0;
            }

            @media (max-width: 999px) {
                text-align: center;
            }
        }
    }
}
