.accordion-item {
    background-color: #FFFFFF;
    margin-bottom: 23px;
    border-radius: 7px;

    @media (max-width: 820px) {
        margin-bottom: 10px;
        padding: 12px 20px;
    }
}

.accordion-header {
    align-items: center;
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    transition: 0.5s;
    padding-right: 35px;
    padding-left: 60px;
    min-height: 90px;

    @media (max-width: 820px) {
        height: 100%;
        padding-left: 20px;
        padding-right: 0;
    }

    &-title {
        color: #412669;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: normal;
        max-width: 1082px;

        @media (max-width: 820px) {
            font-size: 20px;
            width: 90%;
        }
    }

    &-imagem {
        margin-top: 6px;
        transition: 0.5s;

        @media (max-width: 820px) {
            margin-top: 0;
        }

        img {
            width: 24px;
            height: 15px;

            @media (max-width: 820px) {
                width: 17px;
                height: 10px;
            }
        }
    }
}

.accordion-content {
    color: #412669;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    height: 0;
    line-height: 150%;
    overflow: hidden;
    transition: 0.5s;
    max-width: 1074px;
    padding-left: 60px;

    @media (max-width: 820px) {
        font-size: 16px;
        line-height: 179.5%;
        padding-left: 20px;
    }

    ul {
        margin-left: 30px;
        margin-top: 10px;
    }

    a {
        color: #412669;
    }
}

.active .accordion-content {
    transition: 0.5s;
    padding-bottom: 55px;

    @media (max-width: 820px) {
        padding-bottom: 25px;;
    }
}

.active .accordion-content p:first-child {
    transition: 0.5s;
    padding-top: 0px;

    @media (max-width: 620px) {
       padding-top: 20px;
    }
}



.active .accordion-header {
    transition: 0.5s;

    &-imagem {
        rotate: 179deg;
        transition: 0.5s;
    }
}
