.tranquility {
    padding: 28px 0;

    @media (min-width: 821px) and (max-width: 1280px) {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 50px;
    }

    @media (max-width: 820px) {
        padding-top: 20px;
        padding-right: 30px;
        padding-left: 30px;
        padding-bottom: 80px;
    }

    &-container {
        display: flex;
        justify-content: space-between;
        position: relative;
        max-width: 1246px;
        margin: 0 auto;

        @media (max-width: 820px) {
            justify-content: left;
            flex-wrap: wrap;
        }
    }

    &-image {
        padding-right: 10px;

        @media (max-width: 820px) {
            padding-right: 0;
        }
        
        img {
            width: 663px;
            height: 638px;

            @media (min-width: 821px) and (max-width: 1180px) {
                width: 500px;
                height: auto;
            }

            @media (max-width: 820px) {
                width: 249px;
                height: auto;
            }
        }
    }

    &-content {
        max-width: 472px;
        text-align: right;
        margin-top: 80px;

        @media (max-width: 820px) {
            margin-left: 0;
            text-align: left;
            margin-top: 30px;
        }
        
        &-title {
            h2 {
                font-size: 40px;
                color: #412669;
                font-weight: 700;
                line-height: 103.5%;
                margin-bottom: 35px;

                @media (max-width: 820px) {
                    font-size: 36px;
                    margin-bottom: 30px;
                }
            }
        }

        &-text {
            font-size: 18px;
            color: #412669;
            font-weight: 400;
            line-height: 150%;
            margin-bottom: 47px;

            @media (max-width: 820px) {
                font-size: 18px;
            }

            span {
                text-decoration: underline;
            }
        }

        &-btn {
            @media (max-width: 820px) {
                text-align: center;
            }
        }
    }
}